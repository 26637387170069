@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,700;1,800;1,900&display=swap");

body {
  box-sizing: border-box;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary: #723c14;
  --white: #ffffff;
  --yellow: #f7ca18;
  --gray: #757575;
}
::selection {
  color: var(--white);
  background: var(--primary);
}
.navbar .navbar-toggler {
  top: 1rem !important;
  right: 1rem;
  border: none !important;
}

.navbar-toggler-icon {
  filter: invert(1) !important;
}

.form-control {
  border-radius: 0 !important;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none !important;
  border-color: var(--primary) !important;
}

input.form-control::placeholder {
  text-transform: uppercase;
}

.donation-page .form-select {
  border: none;
}
/* Topheader */
.alumini-btn {
  width: 100%;
  display: inline-block;
  background-color: var(--primary);
  color: #fff;
  border: 4px solid var(--primary);
  letter-spacing: 1px;
  font-weight: 600;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  min-width: 45%;
  transition: all 0.3s ease-in-out;
}

.alumini-btn:hover {
  color: #fff;
}

.cs-border {
  border: 1px solid #ced4da;
  padding: 0.35rem 0;
  color: #ced4da;
}

.admin .navbar-nav > img {
  margin: auto;
  transform: scale(0.7);
}

.admin .navbar-nav > a {
  padding: 0;
  font-size: 10px;
}

.admin .navbar-nav {
  margin-right: 1rem;
}
.back-btn {
  padding-bottom: 2.5rem;
}
.back-btn a {
  font-size: 22px;
  background: var(--primary);
  padding: 0 10px;
  color: #fff;
  border-radius: 4px;
}
.back-btn span {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}
.back-btn a .fa {
  color: #fff;
}

.w-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: auto;
}

/* Foundation day event css */
.eventposter{
    /* background-image: url(./assets/kumar.png);
    height: 60vh;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center; */
    width: 100%;
    max-width: 75%;
    margin: auto;
    text-align: center;
    margin-top: 3%;
    margin-bottom: 2%;
}
.eventposter img {
  box-shadow: 0 0 5px 0px #c1bcbc;
  padding: 5px;
  border-radius: 8px;
}
.grant_event{
  width: 75%;
  margin: auto;
}
.grant_event h2.title {
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 2px;
  color: #723c14;

}

select.form-control.form-select.border-light {
  border: 1px solid #ced4da !important;
  margin-top: 0.5rem;
}

.details-list {
  background: #7c515112;
  padding:3rem 1.5rem;
  border-radius: 8px;
  width: 100%;
  max-width: 71.5%;
  margin: auto;
  margin-top: 1rem;
  box-shadow: 0 0 4px 0 #b3b0b0;
  border: 5px solid #fff;
}
.details-list p.title {
  font-size: 18px;
  font-weight: 600;
  color: #723c14;
  opacity: 0.9;
  margin-bottom: 6px;
  padding-top: 1rem;
}
.details-list p.title .fa{
  margin-right: 8px;
}
.details-list p.item {
  margin-bottom: 5px;
  padding-left: 40px;
  font-size: 16px;
  line-height: 18px;
  position: relative;
  word-break: break-all;
}
.details-list p.item:before {
  content: "";
  width: 6px;
  height: 6px;
  background: #723c14;
  position: absolute;
  top: 5px;
  left: 25px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}
.get-pass a {
  border: 1px solid #7f4f2b;
  border-radius: 0px;
  background: #723c14;
  color: #fff;
  text-transform: uppercase;
  padding: 1rem;
  width: 30%;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
}

.get-pass {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 3rem;
}

.get-pass a:hover {
  background: #7f4f2bc4;
  transition: all 0.3s ease-in-out;
  color: #fff;
}
h4.vanue-gsits {
  text-align: center;
  padding-top: 3rem;
}
.message-success {
  background: #e3fdf1;
  padding: 15px;
  border-radius: 5px;
}
/* pagination.css */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  padding: 8px 16px;
  text-decoration: none;
  color: #000000;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.pagination a:hover {
  background-color: #e9ecef;
  color: #723c14;
}

.pagination .selected a {
  background-color: #723c14;
  color: white;
  /* border-color: #723c14; */
}

/* Media Query start */
@media screen and (max-width: 992px) {
  .mobile-hide {
    display: none;
  }
  .back-btn {
    padding-bottom: 1rem;
  }
  .back-btn a {
    font-size: 18px;
  }
  .back-btn span {
    font-size: 16px;
  }
  .eventposter {
    max-width: 71%;
  }
  .grant_event h2.title{
    font-size: 30px;
  }
  .details-list h2.sub-titile {
    font-size: 20px;
}
}

@media screen and (max-width: 767px) {
  p {
    font-size: 14px;
  }

  nav.navbar {
    background-color: var(--white);
    padding: 10;
    box-shadow: none;
  }

  a.navbar-brand {
    background: transparent;
    padding: 5px;
    margin: 0;
    box-shadow: none;
  }

  .mobile-show {
    display: block;
  }
  .alumini-btn {
    max-width: 100% !important;
    padding: 0.5rem;
    font-size: 14px;
  }
  .search_table_admin {
    width: 100%;
    overflow-x: auto;
    padding: 0 1.25rem;
  }

  .search_table_admin table {
    width: 900px;
    border: 1px solid #999;
  }
  .eventposter,
  .details-list {
    max-width: 95%;
}
.grant_event h2.title {
  padding-bottom: 1rem;
}
/* Findation page */
.grant_event h2.title {
  font-size: 22px;
}

.details-list p.title {
  font-size: 16px;
}

.details-list p.item {
  line-height: 22px;
}

.get-pass {
  flex-direction: column;
  gap: 1rem;
}

.get-pass a {
  width: 100%;
}

h4.vanue-gsits {
  font-size: 20px;
}
}